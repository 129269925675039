<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">BUYERS</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button squared variant="primary" v-b-modal.add-buyer>
          <b-icon-plus></b-icon-plus> New Buying Agent
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <b-row class="m-0 w-100">
        <b-col>
          <b-select
            class="form-select mr-2"
            v-model="criteria.chosenCrop"
            :options="crops"
          >
            <template #first>
              <b-select-option selected :value="null"
                >All Produce</b-select-option
              >
            </template>
          </b-select>

          <b-form class="d-inline-block">
            <b-form-input
              placeholder="Search Name"
              type="search"
              class="search"
              v-model="criteria.searchfield"
            ></b-form-input>
          </b-form>
        </b-col>
        <b-col cols="auto">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel class="btn btn-opt primary" type="csv">
              <b-icon-book></b-icon-book>
              Export
            </download-excel>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      :filter="criteria"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No Buying Agents yet"
      @row-clicked="goToBuyer"
      thead-class="bg-primary-tb text-primary-tb border-primary-tb"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <create-buyer @buyer-added="addBuyer"></create-buyer>
  </div>
</template>
<script>
import moment from 'moment';
import * as api from "../api";
import CreateBuyer from "./CreateBuyer.vue";
export default {
  components: { CreateBuyer },
  data() {
    return {
      crops: [],
      criteria: {
        searchfield: null,
        chosenCrop: null,
      },
      isBusy: false,
      items: [],
      fields: [
        "name",
        {
          key: "wallet.balance",
          label: "Amount at hand",
          class:"text-primary",
          /**
           * @param {number} value
           */
          formatter:value=>{
              return "UGX "+value.toLocaleString()
          }
        },
        {
          key: "created_at",
          label: "Date added",
          formatter:value=>{
              return moment(value).format("YYYY-MM-DD");
          }
        },
      ],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    reload() {
      this.$router.go(0);
    },
    /**
     * @param {{value_chain:string}[]} data
     */
    getCrops(data) {
      this.crops = data.map((el) => el.value_chain);
    },
    fetch() {
      this.isBusy = true;
      api.blist().then((data) => {
        this.getCrops(data);
        this.items = data;
        this.isBusy = false;
      });
    },
    /**
     * @param {api.Buyer} buyer
     */
    addBuyer(buyer) {
      this.items = [buyer, ...this.items];
    },
    goToBuyer(buyer){
      this.$router.push({ name: 'buyer-home', params: { buyer_id: buyer.id }})
    }
  },
};
</script>