<template>
  <b-modal
    title="Add Buying Agent"
    id="add-buyer"
    hide-footer
    v-model="isShown"
  >
    <b-overlay :show="isBusy" rounded="sm" spinner-variant="primary">
      <b-form @submit.prevent="addBuyer">
        <b-form-group label="User Type">
          <b-form-select
            :options="user_types"
            v-model="form.user_type"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-group label="Agent Name">
          <vue-bootstrap-typeahead
            v-model="form.name"
            :serializer="(s) => s.va_name + ',' + s.va_phonenumber"
            :data="agents"
            @hit="onUserSelect"
            required
          >
          </vue-bootstrap-typeahead>
        </b-form-group>
        <b-form-group label="Phone Number">
          <b-form-input
            disabled
            v-model="form.phone_no"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="District">
          <b-form-input
            disabled
            v-model="form.district"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Village">
          <b-form-input disabled v-model="form.village" required></b-form-input>
        </b-form-group>
        <div class="w-100">
          <b-button type="submit" variant="primary" class="fl-rht"
            >Add Buying Agent</b-button
          >
          <b-button variant="outline-primary" class="fl-rht mr-1" @click="close"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<style scoped>
.fl-rht {
  float: right;
}
</style>
<script>
import * as api from "../api";
export default {
  data() {
    return {
      form: {
        name: "",
        user_type: "va",
        phone_no: "",
        district: "",
        village: "",
      },
      user_types: [
        { text: "Village Agent", value: "va" },
        // {label:"Farmer Group",value:"farmer_group"}
      ],
      agents: [],
      isShown: false,
      isBusy: false,
    };
  },
  mounted() {
    api.find_vas().then((data) => {
      this.agents = data;
    });
  },
  methods: {
    /**
     * @param {api.Agent} user
     */
    onUserSelect(user) {
      this.form.district = user.va_district;
      this.form.name = user.va_name;
      this.form.phone_no = user.va_phonenumber;
      this.form.village = user.va_village;
    },

    addBuyer() {
      if (this.form.name.length) {
        this.isBusy = true;
        api
          .register(this.form)
          .then((data) => {
            this.$emit("buyer-added", data);
            this.reset();
            this.isShown = false;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            this.$bvToast.toast("Failed", {
              title: "Failed to add Agent",
              variant: "danger",
              solid: true,
            });
            this.$bvToast.toast(error.response.data.message, {
              title: "Failed to add Agent",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        this.$bvToast.toast("Type and select agent under agent name", {
          title: "Select an agent",
          variant: "info",
          solid: true,
        });
      }
    },
    reset() {
      this.form = {
        name: "",
        user_type: "va",
        phone_no: "",
        district: "",
        village: "",
      };
    },
    close() {
      this.reset();
      this.isBusy = false;
      this.isShown = false;
    },
  },
};
</script>
